<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Vehicle Ownership</h6>

            <!-- Title -->
            <h1 class="header-title">Profile</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="card card-fill">
                  <!-- Body -->
                  <div class="card-body text-center">

                    <!-- Image -->
                    <a href="profile-posts.html" class="avatar avatar-xl mb-3">
                      <img src="assets/img/avatars/profiles/avatar-1.jpg"
                      class="avatar-img rounded-circle border border-4 border-card" alt="...">
                    </a>

                    <!-- Heading -->
                    <h2 class="card-title">
                      <a href="profile-posts.html">{{ user.displayName }}</a>
                    </h2>

                    <!-- Text -->
                    <p class="small text-muted mb-3">
                     {{ user.email }}
                    </p>

                  </div>

                  <!-- Footer -->
                  <div class="card-footer card-footer-boxed">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-auto">

                        <!-- Status -->
                        <small v-if="user.emailVerified">
                          <span class="text-success">●</span> Email Verified
                        </small>
                        <small v-else>
                          <span class="text-danger">●</span> Email Not Verified
                        </small>

                      </div>
                      <div class="col-auto">

                        <!-- Link -->
                        <a href="#!" class="btn btn-sm btn-primary">
                          Update
                        </a>

                      </div>
                    </div>
                  </div>

                </div>
        </div>
        <!--  -->
      <div class="col-6">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Management</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a href="#" class="text-muted"> Transporter Name </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Transporter Email </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted">My Next Of Kin</a>
                  </th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="transporter in dataResults" :key="transporter.id">
                  <td class="goal-status">
                    {{ transporter.companyName }}
                  </td>
                  <td class="goal-progress">
                    {{ transporter.transporterEmail }}
                  </td>
                  <td class="goal-progress">
                    {{ transporter.nokFullName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>
<script>
import { ref } from 'vue';
// import { ref, onMounted } from '@vue/runtime-dom';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'OwnerProfile',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    const { user } = getUser();
    const profile = ref('');
    const error = ref('');
    const dataResults = ref('');

    const ownerProfile = async () => {
      /**
       * Get profile of customer
       */
      try {
        await pentermFirestore
          .collection('transporterVehicleOwners')
          .where('ownerEmail', '==', user.value.email)
          .get()
          .then((results) => {
            results.docs.forEach((data) => {
              profile.value = data.data();
            });
            dataResults.value = results.docs.map((vehicle) => ({
              ...vehicle.data(),
              id: vehicle.id,
            }));
          });
      } catch (err) {
        error.value = err.message;
      }
    };
    ownerProfile();

    return {
      user,
      profile,
      dataResults,
    };
  },
};
</script>
<style>
</style>
