import { createRouter, createWebHistory } from 'vue-router';
// import firebase from 'firebase';
// import Home from '../views/Home.vue';
import Denied from '../views/404.vue';
import Help from '../views/Help.vue';
import Login from '../components/Login.vue';
import Redirect from '../components/LoginRedirect.vue';
import SignUpComplete from '../components/SignUpComplete.vue';
import Signup from '../components/Signup.vue';
// Penterm
import PentermJobs from '../components/dashboard/penterm/Jobs.vue';
import PentermJobReport from '../components/dashboard/penterm/JobReport.vue';
import PentermJobEdit from '../components/dashboard/penterm/JobEdit.vue';
import PentermJobDetail from '../components/dashboard/penterm/JobDetail.vue';
// import PentermJobTracker from '../components/dashboard/penterm/JobTracker.vue';
// Transporter
import TransporterDashboard from '../components/dashboard/transporter/DashBoard.vue';
import TransporterNewJob from '../components/dashboard/transporter/AddJob.vue';
import TransporterQuotes from '../components/dashboard/transporter/Quotes.vue';
import TransporterJobs from '../components/dashboard/transporter/Jobs.vue';
import TransporterJobReport from '../components/dashboard/transporter/JobReport.vue';
import TransporterJobTracker from '../components/dashboard/transporter/JobTracker.vue';
import TransporterLoadBoardEdit from '../components/dashboard/transporter/LoadBoardEdit.vue';
import TransporterVehicles from '../components/vehicles/Vehicles.vue';
// Profile
import OwnershipProfile from '../components/dashboard/transporter/Profile.vue';
//
import { pentermAuth } from '../firebase/config';
// auth guard
const requireAuth = (to, from, next) => {
  const user = pentermAuth.currentUser;
  // console.log('current user..is...', user);
  if (!user) {
    next({ name: 'Login' });
  } else {
    next();
  }
};

// if (adminStatus.value === null) {
//   console.log('Router --> false', adminStatus);
// }
// auth guard
// const requireNoAuth = (to, from, next) => {
//   const user = pentermAuth.currentUser;
//   // console.log('current user..is...', user)
//   if (user) {
//     next({ name: 'Home' });
//   } else {
//     next();
//   }
// };
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   // beforeEnter: requireNoAuth,
  // },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/page-not-found',
    name: 'Error',
    component: Denied,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/home',
    name: 'Redirect',
    component: Redirect,
  },
  {
    path: '/activate-account/:email',
    name: 'SignUpComplete',
    component: SignUpComplete,
  },

  // penterm
  {
    path: '/help',
    name: 'Help',
    component: Help,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-report',
    name: 'PentermJobReport',
    component: PentermJobReport,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/jobs',
    name: 'PentermJobs',
    component: PentermJobs,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-edit/:id',
    name: 'PentermJobEdit',
    component: PentermJobEdit,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-detail/:id',
    name: 'PentermJobDetail',
    component: PentermJobDetail,
    props: true,
    beforeEnter: requireAuth,
  },
  // End of Penterm Jobs
  {
    path: '/ownership-profile',
    name: 'OwnershipProfile',
    component: OwnershipProfile,
    props: true,
    beforeEnter: requireAuth,
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: DashBoard,
  //   props: true,
  //   beforeEnter: requireAuth,
  // },
  // Transporter Menu
  {
    path: '/dashboard/',
    name: 'TransporterDashboard',
    component: TransporterDashboard,
    // props: true
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/vehicles',
    name: 'TransporterVehicles',
    component: TransporterVehicles,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/customer/quotes',
    name: 'TransporterQuotes',
    component: TransporterQuotes,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/add-job',
    name: 'TransporterNewJob',
    component: TransporterNewJob,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/customer/jobs',
    name: 'TransporterJobs',
    component: TransporterJobs,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/job-report',
    name: 'TransporterJobReport',
    component: TransporterJobReport,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/loadboard/:id',
    name: 'TransporterLoadBoardEdit',
    component: TransporterLoadBoardEdit,
    // props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard/transporter/job-tracker/:id',
    name: 'TransporterJobTracker',
    component: TransporterJobTracker,
    // props: true,
    beforeEnter: requireAuth,
  },
  // { path: '/*', redirect: '/' },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
