<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Support</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
        <div class="row">
      <div class="col-8 col-lg-8">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
      <div class="col-4 col-lg-4">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'Support',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    return {

    };
  },
};
</script>
<style>

</style>
