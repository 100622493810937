<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Quotes</h6>

            <!-- Title -->
            <h1 class="header-title">Create New Quote</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <router-link class="btn btn-secondary lift"
            :to="{ name: 'TransporterJobs'}">
              Cancel
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6">
          <!-- Card -->
      <div class="card">
        <div class="card-header">
            New Quote
        </div>
        <div class="card-body">
          <!-- check for vehicle -->
             <!-- form to add vehicle -->
        <form @submit.prevent="getDistanceMatrix">
        <!--  -->
          <div class="py-2">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <!-- truck information -->
                <div class="py-2">
                    <label >Pickup Date</label>
                    <input class="form-control" type="text" v-model="pickupDate"
                    required ref="jobDatePicker">
                </div>
                <!-- end -->
              </div>
              <div class="col-sm-12 col-md-6">
                <!-- truck information -->
                <div class="py-2">
                    <label >Vehicle Category</label>
                      <select class="form-select" required v-model="vehicleCategory">
                        <option
                        v-for="vehicle in activeVehicles" :key="vehicle.id"
                        :value="vehicle.categoryName">
                          {{ vehicle.categoryName }}
                        </option>
                      </select>
                </div>
                <!-- end -->
              </div>
            </div>
          </div>
          <div class="py-2">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="py-2">
                    <label >Pickup Contact</label>
                    <input type="text" class="form-control"
                    v-model="pickUpContact" autocomplete="off"
                     required>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="py-2">
                    <label >Phone</label>
                    <input type="tel" class="form-control"
                    v-model="pickUpContactPhone" autocomplete="off"
                    placeholder="+23324412345" required>
                </div>
              </div>
            </div>
          </div>
        <div class="py-1">
            <small style="color: #cc0000;" class="text-center">
              Type in the location name and select from the dropdown.
              </small>
                   <!-- end -->
          <!-- <button @click="getUserLocation" class="btn btn-penterm">
            <i class="fas fa-map-marker-alt"></i> Use My location
            </button> -->
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
            <label for="pickup">Pickup Location</label>
            <vue-google-autocomplete :country="['gh']"
                ref="pickupLocation"
                types=""
                id="pickup"
                classname="form-control"
                placeholder="Pickup location"
                required
                v-on:placechanged="getPickupAddressData"
            ></vue-google-autocomplete>
            </div>
            <!--  -->
            <div class="col-sm-12 col-md-6">
              <label for="dropoff">Destination</label>
                <vue-google-autocomplete :country="['gh']"
                      ref="dropoffLocation"
                      types=""
                      id="dropoff"
                      classname="form-control"
                      placeholder="Dropoff location"
                      required
                      v-on:placechanged="getDropoffAddressData"
                  >
                  </vue-google-autocomplete>
            </div>
          </div>
          <div class="py-2">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="py-2">
                    <label >Destination Contact</label>
                    <input type="text" class="form-control"
                    v-model="dropoffContact" autocomplete="off"
                     required>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="py-2">
                    <label >Phone</label>
                    <input type="tel" class="form-control"
                    v-model="dropoffContactPhone" autocomplete="off"
                    placeholder="+23324412345" required>
                </div>
              </div>
            </div>
          </div>
    <div class="row mb-3">
          <!--  -->
              <div class="col-xs-12 col-md-3">
                <div class="py-2 field-margin">
                  <label for="floatingSelectGrid">Load Type</label>
                  <select class="form-select" v-model="weight"
                   aria-label="Floating label select" required>
                  <option>Select</option>
                  <option value="bulk">Bulk</option>
                  <option value="bags">Bags</option>
                  <option value="20ft-Container">20ft-Container</option>
                  <option value="2x20ft-Container">2 x 20ft-Container</option>
                  <option value="40ft-Container">40 ft-Container</option>
                  </select>
                </div>
              </div>
              <!--  -->
          <!--  -->
              <div class="col-xs-12 col-md-3" v-if="weight === 'bags'">
                   <div class="py-2 field-margin">
                      <label >No of bags</label>
                      <input type="number" class="form-control" v-model="noOfBags"
                       required>
                    </div>
              </div>
              <!--  -->
          <!--  -->
              <div class="col-xs-12 col-md-3" v-if="weight === 'bags'">
                   <div class="py-2 field-margin">
                      <label >Kg/bag</label>
                      <input type="number" class="form-control" v-model="weightOfBag"
                       required>
                    </div>
              </div>
              <!--  -->
          <!--  -->
              <div class="col-xs-12 col-md-3" v-if="weight === 'bags'">
                   <div class="py-2 field-margin">
                      <label >Tonnes</label>
                      <input type="number" class="form-control"

                      :value="(weightOfBag * noOfBags)/1000" disabled>
                    </div>
              </div>
          <!--  -->
              <div class="col-xs-12 col-md-3" v-if="weight === 'bulk'">
                   <div class="py-2 field-margin">
                      <label >Tonnes</label>
                      <input type="text" class="form-control" v-model="totalTonnage"
                       required>
                    </div>
              </div>
        </div>
        <!-- end desc -->
          <div class="checkbox mb-3">
            <small class="error">{{ warning }}</small>
            <small class="error">{{ error }}</small>
          </div>
          <div class="row text-center">
            <div class="col-sm-6">
                <router-link class="btn btn-secondary btn-sm"
                  :to="{ name: 'TransporterJobs'}">
                  Cancel
                </router-link>
            </div>
            <div class="col-sm-6">
                <button class="w-10 btn btn-danger btn-sm" type="submit">Get Quote</button>
            </div>
              <!-- <button class="w-10 btn btn-danger" type="submit">Book Pickup</button> -->
          </div>

        </form>
      </div>
<!--  -->
<!--  -->
<!--  -->
    </div>
  </div>
  <!-- quote for customer -->
  <div class="col-12 col-lg-6">
          <!-- Card -->
      <div class="card">
        <div class="card-header text-primary">
            Quotation
        </div>
        <div class="card-body">
          <form>
          <div class="list-group list-group-flush mb-4">
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">

                    <!-- Title -->
                    <small class="h4">Vehicle Type</small>

                  </div>
                  <div class="col-auto small text-start">

                    <!-- Text -->
                    <small class="h4 text-muted">
                      {{ customerDetails.vehicleType }}
                    </small>

                  </div>
                </div> <!-- / .row -->
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">

                    <!-- Title -->
                    <small class="h4">Pickup Location</small>

                  </div>
                  <div class="col-auto small text-start">

                    <!-- Text -->
                    <small class="h4 text-muted">
                      {{ pickup.location }}
                    </small>

                  </div>
                </div> <!-- / .row -->
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">

                    <!-- Title -->
                    <small class="h4">Destination</small>

                  </div>
                  <div class="col-auto text-start">

                    <!-- Link -->
                    <span class="h4 text-muted">
                      {{ dropoff.location }}
                    </span>

                  </div>
                </div> <!-- / .row -->
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">

                    <!-- Title -->
                    <small class="h4">Distance</small>

                  </div>
                  <div class="col-auto text-start">

                    <!-- Link -->
                    <span class="h4 text-muted">
                      {{ customerDetails.distance }}
                    </span>

                  </div>
                </div> <!-- / .row -->
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <!-- Title -->
                    <small class="h4">Auto-generated Cost </small>
                  </div>
                  <div class="col-auto text-start">GH&cent;</div>
                  <div class="col-auto text-start">
                    <!-- Link -->
                    {{tripCost}}

                  </div>
                </div> <!-- / .row -->
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col text-start">
                    <button class="btn btn-sm btn-secondary" type="submit"> Cancel </button>
                  </div>

                  <div class="col-auto text-start">
                    <!-- Link -->
                    <button class="btn btn-sm btn-danger" type="submit"> Accept Quote </button>

                  </div>
                </div> <!-- / .row -->
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
        <!-- .row end -->
      </div>
  </div>

  <div class="container-fluid">
  <div class="row">
</div>
</div>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import flatpickr from 'flatpickr';
import firebase from 'firebase/app';
import { useRouter } from 'vue-router';
// import { DatePicker } from 'v-calendar';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';
import usePrice from '../../../composables/getPrice';

export default defineComponent({
  components: {
    VueGoogleAutocomplete,
    // DatePicker,
  },
  setup() {
    const { getPrice } = usePrice();
    const {
      user, userDetails,
    } = getUser();
    const long = ref('');
    const lat = ref('');
    const jobDate = ref('');
    const pickup = ref('');
    const pickUpContact = ref('');
    const pickUpContactPhone = ref('');
    const dropoff = ref('');
    const dropoffContact = ref('');
    const dropoffContactPhone = ref('');
    const pickupDate = ref('');
    const dropoffDate = ref('');
    const jobDetail = ref('');
    const pickupLocation = ref(null);
    const dropoffLocation = ref(null);
    const tripPrice = ref('');
    const error = ref('');
    const warning = ref('');
    const customer = ref('');
    const customerDetails = ref({});
    const customerPhone = ref(null);
    const customerEmail = ref('');
    const tripCost = ref('');
    const fberror = ref('');
    const router = useRouter();
    const trucks = ref('');
    const truckId = ref('');
    const weight = ref('');
    const weightOfBag = ref('');
    const noOfBags = ref('');
    const totalTonnage = ref('');
    const itemDesc = ref('');
    const fuelCost = ref('');
    let goodsWeight = 0;
    const picked = ref(new Date());
    const exampleTo = ref(new Date());
    const showOrderForm = ref(false);
    const intTelNum = ref(null);
    const timezone = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const computedCost = ref('');
    const showForm = ref(false);
    const activeVehicles = ref('');
    const activeVehiclesId = ref('');
    const pickUpDateError = ref('');
    const today = new Date();
    const vehicleCategory = ref('');
    // Date picker
    const jobDatePicker = ref(null);
    const quoteValidity = ref(7);

    const getVehicleSelected = (vehicleSelected) => {
      activeVehiclesId.value = vehicleSelected;
    };
    //
    /**
     * Get Vehicle Categories
     */
    const priceSchedules = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            dataResults.value = snap.docs.map((priceSchedule) => (
              { ...priceSchedule.data(), id: priceSchedule.id }
            ));
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    // End
    const transporterVehicles = async () => {
      // Get Vehicle Categories based on transporter vehicle types
      try {
        await pentermFirestore.collection('vehicleCategories')
          .where('status', '==', 1)
          .onSnapshot((snap) => {
            activeVehicles.value = snap.docs.map((results) => (
              { ...results.data(), id: results.id }
            ));
          });
      } catch (err) {
        error.value = err.message;
      }
    };

    // Show form if valid date is selected.
    const dateClicked = (dateSelected) => {
      if (today >= dateSelected) {
        pickUpDateError.value = 'Pick-up cannot be in the past.';
        showForm.value = false;
      } else {
        pickUpDateError.value = '';
        showForm.value = true;
      }
    };

    const getPickupAddressData = (addressData, placeResultData) => {
      pickup.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };

    const getDropoffAddressData = (addressData, placeResultData) => {
      dropoff.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };

    const { google } = window;

    const getDistanceMatrixAuto = () => {
      // const origin = new google.maps.LatLng(pickup.value.lat, pickup.value.lng);
      const destination = new google.maps.LatLng(dropoff.value.lat, dropoff.value.lng);
      const depart = new Date();
      // const departTime = depart.getTime() / 1000;
      if (pickup.value.place_id && dropoff.value.place_id) {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            drivingOptions: {
              departureTime: depart,
            },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              // console.log(`Error was: ${status}`);
              error.value = 'Please ensure a pickup location, date and destination is selected.';
            } else {
              dump.value = response;
            }
          },
        );
      }
    };

    // Add Order to DB
    const getDistanceMatrix = () => {
      customerDetails.value = {
        name: customer.value,
        phone: customerPhone.value,
        email: customerEmail.value,
        vehicleType: vehicleCategory.value,
      };
      const origin = new google.maps.LatLng(pickup.value.lat, pickup.value.lng);
      const destination = new google.maps.LatLng(dropoff.value.lat, dropoff.value.lng);
      const depart = new Date(pickupDate.value);
      // const departTime = depart.getTime() / 1000;
      if (pickup.value.place_id && dropoff.value.place_id && pickupDate.value) {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            drivingOptions: {
              departureTime: depart,
            },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              // console.log(`Error was: ${status}`);
              error.value = 'Please ensure a pickup location, date and destination is selected.';
            } else {
              if (totalTonnage.value) {
                goodsWeight = totalTonnage.value;
              } else {
                goodsWeight = (weightOfBag.value * noOfBags.value) / 1000;
              }
              // console.log(response);
              jobDetail.value = response;
              const pickupGeoPoint = new firebase.firestore.GeoPoint(
                pickup.value.lat, pickup.value.lng,
              );
              const dropoffGeoPoint = new firebase.firestore.GeoPoint(
                dropoff.value.lat, dropoff.value.lng,
              );
              // Details
              const customerName = customer.value;
              const customerPhoneNo = customerPhone.value;
              const customerEmailAdd = customerEmail.value;
              const pickUpContactName = pickUpContact.value;
              const pickUpContactPhoneNo = pickUpContactPhone.value;
              const dropoffContactName = dropoffContact.value;
              const dropoffContactPhoneNo = dropoffContactPhone.value;
              // Get Cost for trip
              const getDistance = jobDetail.value.rows[0].elements[0].distance.text;
              const totalDistance = Number(getDistance.replace(' km', ''));
              let addTrip = {};
              getPrice(vehicleCategory.value, totalDistance).then((data) => {
                // console.log(data);
                computedCost.value = data.value;

                // End
                addTrip = {
                  tripId: `TJ${Math.floor(Math.random() * 10)}${Date.now()}`,
                  jobDate: timestamp(),
                  customer: customerName,
                  phone: customerPhoneNo,
                  email: customerEmailAdd,
                  pickup: pickup.value.location,
                  pickupCoordinates: pickupGeoPoint,
                  pickupDate: depart,
                  pickUpContact: pickUpContactName,
                  pickUpContactPhone: pickUpContactPhoneNo,
                  dropoff: dropoff.value.location,
                  dropoffCoordinates: dropoffGeoPoint,
                  dropoffContact: dropoffContactName,
                  dropoffContactPhone: dropoffContactPhoneNo,
                  distance: jobDetail.value.rows[0].elements[0].distance.text,
                  itemDesc: itemDesc.value,
                  itemWeightDesc: weight.value,
                  itemWeight: goodsWeight,
                  noOfBags: noOfBags.value,
                  weightOfBag: weightOfBag.value,
                  computedCost: data.value,
                  cost: tripPrice.value,
                  finalRevenue: tripPrice.value,
                  truckRegistration: truckId.value.truckId,
                  truckUid: truckId.value.Id,
                  driverEmail: truckId.value.driverEmail,
                  ownerEmail: truckId.value.ownerEmail,
                  fuelCost: fuelCost.value,
                  finalFuel: fuelCost.value,
                  vehicleType: vehicleCategory.value,
                  jobExpenses: 0,
                  jobMaintenace: 0,
                  jobComments: 'None',
                  username: user.value.displayName,
                  transporterId: userDetails.value.transporterId,
                  uid: user.value.uid,
                  status: 0,
                  createdDate: timestamp(),
                  updatedDate: timestamp(),
                };
                console.log(addTrip);
                // customer details
                customerDetails.value.distance = jobDetail.value.rows[0].elements[0].distance.text;
                tripCost.value = data.value;
                // end
                // const submitData = async () => {
                //   const res = await pentermFirestore.collection('transporterJobs').add(addTrip);
                //   fberror.value = res.message;

                //   // Update truck status to prevent new jobs being added.
                //   const vehicleRef =
                // pentermFirestore.collection('transporterVehicles').doc(truckId.value.Id);
                //   await vehicleRef.update({
                //     jobStatus: 1,
                //   });
                //   // end
                // };
                // end
                // submitData();
              });
              // console.log(user.displayName);
              // console.log(addTrip);
              if (!fberror.value) {
                // console.log('Added to db');
                // router.push({ name: 'TransporterJobs' });
              }
              // Clear fields after submission
              pickupDate.value = '';
              customer.value = '';
              customerPhone.value = '';
              customerEmail.value = '';
              pickupLocation.value.clear();
              dropoffLocation.value.clear();
            }
          },
        );
      } else {
        warning.value = 'Please ensure a pickup location, date and destination is selected.';
      }
    };

    onMounted(() => {
      priceSchedules();
      transporterVehicles();
      flatpickr(jobDatePicker.value, {
        minDate: 'today',
        allowInput: true,
      });
    });

    return {
      pickUpDateError,
      dataResults,
      activeVehicles,
      transporterVehicles,
      long,
      lat,
      jobDate,
      intTelNum,
      timezone,
      pickup,
      pickUpContact,
      pickUpContactPhone,
      dropoff,
      dropoffContact,
      dropoffContactPhone,
      getPickupAddressData,
      getDropoffAddressData,
      tripPrice,
      pickupDate,
      dropoffDate,
      jobDetail,
      pickupLocation,
      dropoffLocation,
      error,
      customer,
      customerDetails,
      customerEmail,
      customerPhone,
      tripCost,
      user,
      userDetails,
      fberror,
      getDistanceMatrix,
      router,
      trucks,
      truckId,
      weight,
      weightOfBag,
      noOfBags,
      totalTonnage,
      fuelCost,
      itemDesc,
      picked,
      exampleTo,
      warning,
      showOrderForm,
      showForm,
      getDistanceMatrixAuto,
      dateClicked,
      getVehicleSelected,
      vehicleCategory,
      jobDatePicker,
      quoteValidity,
    };
  },
});

</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.font-size{
  font-size: 150%;
}
.vehicle-type {
  cursor: pointer;
  border: 1px solid #ccc;
}
.vehicle-type:hover {
  border: 1px solid #c90016;
}
.vehicle-type-selected {
  border: 1px solid #c90016;
}
</style>
