<template>
<!--  -->
<div class="page-body">
  <div class="container col-xl-10 col-xxl-8 px-4 py-5">
    <div class="row align-items-center g-lg-5 py-5">
      <!--  -->
      <div class="col-lg-6 text-center text-lg-start penterm-text">
        <h3 class="display-4 lh-1 mb-3" style="font-size: 150%;">
          Vehicle Owner Sign Up</h3>
        <p class="col-lg-12 fs-5">
         Advanced AI powered logistics platform.
        </p>
        <!-- <img src="../assets/images/pexels-tima-miroshnichenko-5198239.jpg" alt=""
        class="rounded mx-auto d-block img-fluid text-start"> -->
      </div>
      <div class="col-md-10 mx-auto col-lg-6 text-start">
        <h3 class="display-4 lh-1 mb-3 text-center" style="font-size: 150%;">
          Vehicle Owner Sign Up</h3>
        <form @submit.prevent="handleSubmit"
        class="p-4 p-md-5 border rounded-3 bg-light">
          <div class="py-2">
            <label for="floatingInput">Full Name</label>
            <input type="text" class="form-control"
            v-model="displayName" required>
          </div>
          <div class="py-2">
            <label for="floatingInput">Email address</label>
            <input type="email" class="form-control"
            v-model="email" required>
          </div>
          <div class="py-2">
            <label for="floatingPassword">Password</label>
            <input type="password" class="form-control"
            v-model="password" autocomplete="off" required>
          </div>
          <div class="checkbox mb-3">
            <small class="error">{{ error }}</small>
          </div>
          <button class="w-100 btn btn-lg btn-danger" type="submit">Sign Up</button>
          <hr class="my-4">
          <small class="text-muted">By clicking Sign up, you agree to the terms of use.</small>
        </form>
      </div>
      <!-- End of Sign up-->

    </div>
  </div>
</div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useSignup from '../composables/useSignup';
// import useLogin from '../composables/useLogin';
import useLogout from '../composables/useLogout';
// import getUser from '../composables/getUser';

export default {
  name: 'SignupForm',
  setup() {
    // Using imported signup js
    const { error, signup } = useSignup();
    //
    const { errorLogout, logout } = useLogout();
    // const { user } = getUser();
    const loginVisible = ref(false);
    const displayName = ref('');
    const email = ref('');
    const password = ref('');
    const router = useRouter();

    const handleSubmit = async () => {
      // console.log(displayName.value, email.value, password.value);
      await signup(
        email.value,
        password.value,
        displayName.value,
      );
      if (!error.value) {
        // console.log('User Signed Up');
        // context.emit('signup');
        // router.go({ name: 'TransporterDashboard' });
        loginVisible.value = true;
        await logout();
        if (!errorLogout.value) {
        // console.log('User logged out');
        // Redirect on logout
          loginVisible.value = true;
          router.push({ name: 'TransporterDashboard' });
        }
      }
    };
    onMounted(() => {
      // if (user.value) {
      //   router.push({ name: 'TransporterDashboard' });
      // }
    });

    return {
      displayName,
      email,
      password,
      handleSubmit,
      error,
    };
  },
};
</script>
<style scoped>
.page-body {
  background-image: url('../assets/images//gabriel-santos-GBVDilE8yvI-unsplash1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.penterm-text {
  background-color: white;
  opacity: 90%;
  padding: 10px;
  border-radius: 5px;
}
.login{
    width: 70%;
    max-width: 960px;
    margin: 20px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 30, 0.1);
    background: white;
    text-align: center;

}
.welcome{
  text-align: center;
  padding: 20px 0;
  margin: auto;
}
.welcome form {
  width: 300px;
  margin: 20px auto;
}
.welcome label{
  display: block;
  margin: 20px 0 10px;
}
.welcome input{
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  outline: none;
  color: #999;
  margin: 10px auto;
}
.welcome button{
  margin: 20px auto;
}
.login-box{
  display: inline-block;
  margin: 10px;
}
</style>
