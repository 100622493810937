import { ref } from 'vue';
// import firebase from 'firebase';
import { pentermAuth } from '../firebase/config';

const error = ref(null);
const errorLogin = ref(null);
// const adminStatus = ref(null);
// account data
// end

const login = async (email, password) => {
  error.value = null;

  try {
    const res = await pentermAuth.signInWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete login');
    }
    error.value = null;
    errorLogin.value = null;
    //
    return res;
  } catch (err) {
    // console.log(err.value);
    error.value = 'Incorrect Login credentials';
    errorLogin.value = 'Incorrect Login credentials';
    return error;
  }
};

const passwordReset = async (email) => {
  const actionCodeSettings = {
    url: 'https://user.penterm.com/login',
    // iOS: {
    //   bundleId: 'com.example.ios',
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12',
    // },
    handleCodeInApp: true,
    // When multiple custom dynamic link domains are defined, specify which
    // one to use.
    // dynamicLinkDomain: 'example.page.link',
  };
  pentermAuth.sendPasswordResetEmail(email, actionCodeSettings).then(() => {
    // Email sent.
    // console.log('Email sent');
  }).catch(() => {
  // An error happened.
    error.value = 'Problem sending email';
  });
};

const useLogin = () => ({
  error, login, passwordReset, errorLogin,
});

export default useLogin;
