import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth } from '../firebase/config';

const user = ref(pentermAuth.currentUser);
const adminStatus = ref(null);
const getAccountType = ref(null);
const userDetails = ref(null);

pentermAuth.onAuthStateChanged((_user) => {
//   console.log('User state change. current user is ', _user);
  if (window.location.hostname === 'localhost') {
    firebase.functions().useEmulator('localhost', 5001);
  }
  user.value = _user;
  // User Custom Claims
  if (_user) {
    pentermAuth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // console.log(idTokenResult);
        userDetails.value = idTokenResult.claims;
      });
  }
});

const getUser = () => ({
  user,
  adminStatus,
  userDetails,
  getAccountType,
});

export default getUser;
