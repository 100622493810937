<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Fleet</h1>
          </div>
          <!--  -->
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid" v-if="dataResults">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ totalVehicles }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-truck text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Insurance Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 1 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-file text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Mot Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 1 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-file text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">
                  Maintenance
                </h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 1 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-flag text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- list below -->
    <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Fleet</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a href="#" class="text-muted"> License Plate </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Driver </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Insurance Expiry </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> MoT Expiry </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Managed By </a>
                  </th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="vehicle in dataResults" :key="vehicle.id">
                  <td class="goal-status">
                    {{ vehicle.vehicleReg }}
                  </td>
                  <td class="goal-progress">
                    {{ vehicle.driverFullName }}
                  </td>
                  <td class="goal-date">
                    <span
                      style="font-size: 1.2rem"
                      v-if="vehicle.insuranceEnd.toDate() < today"
                    >
                      <span style="color: Red">
                        <i class="fas fa-times-circle px-1"></i>
                      </span>
                    </span>
                    <span style="font-size: 1.2rem" v-else>
                      <span style="color: Green">
                        <i class="far fa-check-circle px-1"></i>
                      </span>
                    </span>
                    {{ vehicle.insuranceEnd.toDate().toDateString() }}
                  </td>
                  <td class="text-start">
                    <span
                      style="font-size: 1.2rem"
                      v-if="vehicle.motEnd.toDate() <= today"
                    >
                      <span style="color: Red">
                        <i class="fas fa-times-circle px-1"></i>
                      </span>
                    </span>
                    <span style="font-size: 1.2rem" v-else>
                      <span style="color: Green">
                        <i class="far fa-check-circle px-1"></i>
                      </span>
                    </span>
                    {{ vehicle.motEnd.toDate().toDateString() }}
                  </td>
                  <td class="goal-progress">
                    {{ vehicle.transporterName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import firebase from 'firebase';
import { ref, onMounted } from '@vue/runtime-dom';
// import { useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { pentermFirestore } from '../../firebase/config';

export default {
  name: '',
  props: [''],
  setup() {
    const { user } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = 25;
    // const fileName = ref('');
    const today = ref('');
    const totalVehicles = ref('');

    today.value = new Date();
    // end
    const vehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('ownerEmail', '==', user.value.email)
          .orderBy('createdDate', 'desc')
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicle) => ({
              ...vehicle.data(),
              id: vehicle.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    const getVehicles = async () => {
      try {
        await pentermFirestore.collection('transporterVehicles')
          .where('ownerEmail', '==', user.value.email).get()
          .then((snap) => {
            totalVehicles.value = snap.size;
          });
      } catch (err) {
        error.value = err.message;
      }
    };
    vehicles();
    getVehicles();
    onMounted(() => {
      vehicles();
      getVehicles();
      // if (userDetails.value.accessType === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });

    return {
      user,
      today,
      loggedInUser,
      dataResults,
      records,
      disableBtn,
      totalVehicles,
    };
  },
};
</script>
<style>
</style>
